<template>
  <div id="dashboard-content">
    <h4 class="mb-4">Agenda Penilaian Buku</h4>
    <div class="card border-0 shadow-sm py-3">
      <div v-if="!$store.state.loadEventPenilaian" class="container">
        <div v-show="eventPenilaian.length == 0" class="alert alert-info">
          Info : Belum Ada Pendaftaran Penilaian Buku
        </div>
        <div class="input-group mb-3 w-50">
          <div class="input-group-prepend border-right-0">
            <span class="input-group-text bg-white" id="basic-addon1">
              <i class="fa fa-search"></i>
            </span>
          </div>
          <input
            type="text"
            class="form-control border-left-0 px-0"
            placeholder="Cari agenda"
            v-model="keyword"
          />
        </div>
        <div class="row">
          <div v-if="filteredEvent.length == 0" class="col-12">
            <EmptyState
              title="Pencarian tidak ditemukan"
              subtitle="Silahkan cari agenda dengan kata kunci lain"
            />
          </div>
          <div
            v-else
            v-for="(event, index) in filteredEvent
              .filter(
                (event) => event.status !== 'draft' && event.mode === 'public'
              )
              .sort((a, b) => (a.status === 'open' ? -1 : 1))
              .sort((a, b) => (a.date_start > b.date_start ? -1 : 1))"
            :key="index"
            class="col-lg-6 mb-3"
          >
            <CardEvent :assessment="event" />
          </div>
        </div>
      </div>
      <div v-else class="text-center">
        <div
          class="spinner-border"
          style="width: 3rem; height: 3rem;"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div
        v-if="!$store.state.loadEventPenilaian && eventPenilaian.length == 0"
        class="text-center mt-5"
      >
        <img width="60" src="@/assets/not-found.png" class="img-fluid" alt="" />
        <h4 class="text-center mt-2">Data tidak ditemukan.</h4>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import EmptyState from "@/components/global/dashboard/EmptyState.vue";
import CardEvent from "../../../components/assessment/Card.vue";

export default {
  name: "UserAgendaPenilaian",
  data() {
    return {
      keyword: "",
    };
  },
  components: { EmptyState, CardEvent },
  computed: {
    ...mapState(["eventPenilaian"]),
    filteredEvent() {
      return this.eventPenilaian.filter((event) => {
        return event.title.toLowerCase().includes(this.keyword.toLowerCase());
      });
    },
  },
  methods: {
    ...mapActions(["fetchAllEventPenilaian"]),
    beforeRouteEnter(_, __, next) {
      const { role_name } = JSON.parse(localStorage.getItem("user"));
      if (role_name === "Penerbit") next();
      else next("/user/home");
    },
  },
  created() {
    this.fetchAllEventPenilaian();
  },
};
</script>
