<template>
  <!-- eslint-disable-next-line vue/no-v-html -->
  <img v-if="icon" :src="icon" />
</template>

<script>
export default {
  props: {
    name: String,
  },
  data() {
    return {
      icon: null,
    };
  },
  async created() {
    // Auto-load icons
    const iconPromises = {};
    const iconFiles = require.context("@/assets/icons", false, /\.svg$/);
    iconFiles.keys().forEach((filename) => {
      const iconName = filename
        .split("/")
        .pop()
        .split(".")
        .shift();

      iconPromises[iconName] = iconFiles(filename);
    });

    if (this.name) {
      const iconComponent = iconPromises[this.name];
      if (iconComponent) {
        this.icon = iconComponent;
      }
    }
  },
};
</script>
